const configs = {
  clientId: 100,
  url: "leads",
  system: "SONOVA-LEADS",
  language: "pt",
  env: "homol",
  cspNonce: "609f530c-3bf8-4034-95ee-08eba5a464c0",
};

export default configs;
