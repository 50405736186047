<template>
  <v-navigation-drawer
    app
    permanent
    :mini-variant="miniVariant"
    class="black px-2 py-4"
    :style="navigationDrawerStyle"
  >
    <div
      class="d-flex"
      :class="miniVariant ? 'justify-center' : 'justify-space-between'"
    >
      <a
        v-if="!miniVariant"
        target="_blank"
        href="https://www.audiumbrasil.com.br/audionova/"
      >
        <img
          src="/img/audio-nova-light.png"
          alt="Audio Nova Logotipo"
          style="max-width: 132px"
        />
      </a>

      <v-btn icon @click="$emit('handleToggleMiniVariant')">
        <v-icon color="white">{{
          miniVariant ? "mdi-arrow-right" : "mdi-arrow-left"
        }}</v-icon>
      </v-btn>
    </div>

    <v-divider class="neutral my-2"></v-divider>

    <div
      class="d-flex flex-column py-2"
      :class="miniVariant ? 'justify-center flex-column' : ''"
    >
      <div class="d-flex" :class="miniVariant ? 'flex-column' : ''">
        <v-icon
          small
          :color="sessionExpirationIconColor"
          :class="{ 'mr-2': !miniVariant }"
        >
          mdi-clock-outline
        </v-icon>

        <span v-show="!miniVariant" class="text-caption white--text mr-2">
          {{ `Sessão expira em ${formattedSessionExpirationCountdown}` }}
        </span>

        <span
          v-show="miniVariant"
          class="text-caption white--text text-center mt-2"
        >
          {{ formattedSessionExpirationCountdown }}
        </span>

        <v-tooltip top v-if="!miniVariant">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              x-small
              color="white"
              :loading="loadingRenewSession"
              @click="handleRenewSession()"
            >
              <v-icon small color="white"> mdi-autorenew </v-icon>
            </v-btn>
          </template>
          <span>Renovar sessão</span>
        </v-tooltip>
      </div>

      <v-row no-gutters class="mt-2" v-show="!miniVariant">
        <v-col
          @click="navigateToUserProfile()"
          class="cursor-pointer text-center"
        >
          <v-icon small color="white" class="mr-2">mdi-account</v-icon>
          <span class="text-caption white--text text-center mt-2">
            Editar perfil
          </span>
        </v-col>
      </v-row>
    </div>

    <v-divider class="neutral my-2"></v-divider>

    <v-list>
      <v-list-item
        v-for="menu in visibleMenus"
        :key="menu.id"
        @click="$emit('handleNavigate', menu)"
      >
        <v-list-item-icon>
          <v-tooltip right v-if="miniVariant">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="setMenuColor(menu)">
                {{ menu.icon }}
              </v-icon>
            </template>
            <span>{{ menu.text }}</span>
          </v-tooltip>

          <v-icon v-else :color="setMenuColor(menu)">
            {{ menu.icon }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text" data-testid="submenu-item-label">
            {{ menu.text }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action @click.stop="$emit('handleOpenInNewTab', menu)">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <v-icon v-on="on" color="white" small> mdi-open-in-new </v-icon>
              </v-btn>
            </template>
            <span>Abrir em nova aba</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider class="neutral my-2"></v-divider>

    <v-list>
      <v-list-item @click="$emit('handleLogout')">
        <v-list-item-icon>
          <v-tooltip right v-if="miniVariant">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="white"> mdi-logout </v-icon>
            </template>
            <span>Sair</span>
          </v-tooltip>

          <v-icon v-else color="white"> mdi-logout </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text"> Sair </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <p
      v-if="!miniVariant"
      class="text-caption white--text text-center"
      style="position: absolute; bottom: 0; width: 100%"
    >
      v. 1.0.0
    </p>
  </v-navigation-drawer>
</template>

<script>
import api from "@/services/api";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ZnapNavigationDrawer",

  props: {
    miniVariant: { type: Boolean, required: true, default: false },
    menus: { type: Array, required: true },
    selectedMenu: { type: Object, required: true },
  },

  data() {
    return {
      countdownInterval: null,
      sessionRemainingTime: null,
      loadingRenewSession: false,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "getUserName",
      "getUserEmail",
      "getUserPhoto",
      "getTokenIat",
      "getTokenExp",
    ]),

    navigationDrawerStyle() {
      return {
        width: this.miniVariant ? "68px" : "356px",
      };
    },

    sessionExpirationCountdown() {
      const timeRemaining = this.sessionRemainingTime;

      const hours = Math.floor(timeRemaining / 3600);
      const minutes = Math.floor((timeRemaining % 3600) / 60);
      const seconds = timeRemaining % 60;

      return { hours, minutes, seconds };
    },

    formattedSessionExpirationCountdown() {
      const { hours, minutes } = this.sessionExpirationCountdown;

      return `${hours.toString().padStart(2, "0")}h e ${minutes
        .toString()
        .padStart(2, "0")}m`;
    },

    sessionExpirationIconColor() {
      if (this.sessionRemainingTime <= 360) {
        return "signalColorMagenta";
      }

      if (this.sessionRemainingTime <= 960) {
        return "affordabilityOrange";
      }

      return "serviceGreen";
    },

    visibleMenus() {
      return this.menus.filter((m) => !m.hide);
    },
  },

  watch: {
    getTokenExp(newVal, oldVal) {
      if (newVal > oldVal) {
        this.updateCountdown();
      }
    },
  },

  mounted() {
    this.updateCountdown();
    this.countdownInterval = setInterval(this.updateCountdown, 60000);
  },

  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },

  methods: {
    ...mapActions("auth", ["setRenewSession"]),

    navigateToUserProfile() {
      if (this.$route.path !== "/user-profile") {
        this.$router.push("/user-profile");
      }
    },

    setMenuColor(menu) {
      if (
        this.selectedMenu !== null &&
        this.selectedMenu.id === menu.id &&
        this.$route.path === this.selectedMenu.route
      ) {
        return "affordabilityOrange";
      }

      return "white";
    },

    updateCountdown() {
      const tokenExp = this.getTokenExp;

      const currentTime = Math.floor(Date.now() / 1000);
      this.sessionRemainingTime = tokenExp - currentTime;
    },

    async handleRenewSession() {
      this.loadingRenewSession = true;

      const res = await this.setRenewSession();
      if (res) {
        if (res.status === "success") {
          this.$toast.success(res.msg);
          this.loadingRenewSession = false;
        }

        if (res.status === "error") {
          api.handleError(res.error);
          this.loadingRenewSession = false;
        }
      }
    },
  },
};
</script>

<style>
</style>