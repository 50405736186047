import axios from "axios";
import store from "@/store";

axios.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    if (store.getters["auth/isAuth"]) {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.getters["auth/getToken"]}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
