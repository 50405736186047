import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/es5/locale/pt";
import configs from "../configs/configs.js";

const cspNonce = configs.cspNonce;

Vue.use(Vuetify, { cspNonce: cspNonce });

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 800,
  },
  theme: {
    options: { customProperties: true, variations: false, cspNonce: cspNonce },
    themes: {
      light: {},
      dark: false,
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
