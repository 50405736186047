import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "LoginView") {
    if (store.getters["auth/isAuth"]) {
      return router.push("/home");
    }
  } else {
    if (!store.getters["auth/isAuth"]) {
      return router.push("/");
    }
  }

  const savedHash = from.query.filter_hash;

  if (savedHash && to.query.filter_hash !== from.query.filter_hash && to.query.filter_hash !== savedHash) {
    router.push(`${to.path}?filter_hash=${savedHash}`)
  } else {
    next();
  }
});

export default router;
