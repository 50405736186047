import api from "@/services/api";
import jwt_decode from "jwt-decode";

const initialState = () => {
  return {
    id: null,
    name: null,
    email: null,
    photo: null,
    temporary_password: 0,
    token: null,
    token_iat: null,
    token_exp: null,

    systemConfigsMenu: [],
    permissions: [],
  };
};

const state = initialState();

const getters = {
  isAuth: (state) => {
    if (!state.token) {
      return false;
    }

    return Date.now() / 1000 <= state.token_exp;
  },

  getToken: (state) => state.token,
  getTokenIat: (state) => state.token_iat,
  getTokenExp: (state) => state.token_exp,
  getUserId: (state) => state.id,
  getUserName: (state) => state.name,
  getUserEmail: (state) => state.email,
  getUserPhoto: (state) => state.photo,
  getUserTemporaryPassword: (state) => state.temporary_password,
  getSystemConfigsMenu: (state) => state.systemConfigsMenu,
  getPermissions: (state) => state.permissions,
};

const mutations = {
  setId(state, id) {
    state.id = id;
  },

  setHash(state, hash) {
    state.hash = hash;
  },

  setToken(state, token) {
    state.token = token;

    if (token) {
      state.token_iat = jwt_decode(token).iat;
      state.token_exp = jwt_decode(token).exp;
    } else {
      state.token_iat = null;
      state.token_exp = null;
    }
  },

  setName(state, name) {
    state.name = name;
  },

  setEmail(state, email) {
    state.email = email;
  },

  setPhoto(state, photo) {
    state.photo = photo;
  },

  setTemporaryPassword(state, temporaryPassword) {
    state.temporary_password = temporaryPassword;
  },

  setSystemConfigsMenu(state, payload) {
    state.systemConfigsMenu = payload;
  },

  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};

const actions = {
  async setAuth({ commit, dispatch }, payload) {
    try {
      const res = await api.call(
        "post",
        "user",
        "LEADS_SECURITY",
        "user",
        "/login",
        payload
      );

      if (res) {
        const userId = res.data.id[0];
        commit("setId", userId);
        commit("setHash", res.data.hash);
        commit("setToken", res.data.token);
        commit("setPermissions", res.data.transactionCodes);

        dispatch("setSystemConfigsMenu");

        const userRes = await api.call(
          "post",
          "user",
          "LEADS_SECURITY",
          "user",
          `/list/${userId}`
        );
        if (userRes) {
          const user = userRes.data.rows[0];

          commit("setName", user.name);
          commit("setEmail", user.email);
          commit("setPhoto", user.photo);
          commit("setTemporaryPassword", user.temporary_password);

          return {
            status: "success",
          };
        }
      }
    } catch (error) {
      return {
        status: "error",
        error,
      };
    }
  },

  async setAuthByToken({ commit, dispatch }, payload) {
    try {
      const res = await api.call(
        "post",
        "user",
        "LEADS_SECURITY",
        "user",
        "/get-hash",
        payload
      );

      if (res) {
        const userId = res.data.id[0];
        commit("setId", userId);
        commit("setHash", res.data.hash);
        commit("setToken", res.data.token);
        commit("setPermissions", res.data.transactionCodes);

        dispatch("setSystemConfigsMenu");

        const userRes = await api.call(
          "post",
          "user",
          "LEADS_SECURITY",
          "user",
          `/list/${userId}`
        );
        if (userRes) {
          const user = userRes.data.rows[0];

          commit("setName", user.name);
          commit("setEmail", user.email);
          commit("setPhoto", user.photo);
          commit("setTemporaryPassword", user.temporary_password);

          return {
            status: "success",
          };
        }
      }
    } catch (error) {
      return {
        status: "error",
        error,
      };
    }
  },
  
  async setRenewSession({ commit }) {
    try {
      const res = await api.call(
        "post",
        "user",
        "LEADS_SECURITY",
        "user",
        "/renew-session"
      );

      if (res) {
        commit("setHash", res.data.hash);
        commit("setToken", res.data.token);

        return {
          status: "success",
          msg: res.data.msg,
        };
      }
    } catch (error) {
      return {
        status: "error",
        error,
      };
    }
  },

  setLogout({ commit }) {
    commit("setHash", null);
    commit("setToken", null);

    return {
      status: "success",
    };
  },

  async setSystemConfigsMenu({ commit }) {
    const mainMenuTransactionCodes = [
      "LOGIN",
      "READ_LEADS",
      "READ_CAMPAIGN",
      "READ_RESALE",
    ];

    try {
      const menuRes = await api.call(
        "post",
        "user",
        "LEADS_PORTAL",
        "user",
        "/menu"
      );

      if (menuRes) {
        const moduleMenus = menuRes.data.map((m) => {
          return {
            transactionCode: m.transactionCode,
            text: m.label,
            childrens: [],
            transactionSequence: m.transaction_sequence,
          };
        });

        const systemConfigsMenu = [];
        for (const moduleMenu of moduleMenus) {
          const res = await api.call(
            "post",
            "user",
            moduleMenu.transactionCode,
            "user",
            "/menu"
          );

          if (res) {
            moduleMenu.childrens = res.data.filter(
              (m) => !mainMenuTransactionCodes.includes(m.transactionCode)
            );
            systemConfigsMenu.push(moduleMenu);
          }
        }

        commit("setSystemConfigsMenu", systemConfigsMenu);
      }
    } catch (error) {
      api.handleError(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
