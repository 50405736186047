export default {
  neutral: "#768288",
  neutralLight: "#F5F6F7",
  neutralDark: "#38454B",

  primary: "#8BC63E",
  primaryLight: "#C7D300",
  primaryDark: "#367E9E",

  warning: "#FFBE60",
  error: "#E4003E",
  success: "#8BC63E",

  // Sonova Colors
  signalColorViolet: "#6F1E82",
  signalColorMagenta: "#E4003E",
  affordabilityOrange: "#F78E1E",
  signalColorSun: "#FFCC00",
  signalColorYellow: "#FFED00",
  accentColorGreenTone: "#C7D300",
  serviceGreen: "#8BC63E",
  accentColorPetrolTone: "#FFED00",
  accentColorPetrol: "#08AF97",
  accentColorBlueTone: "#00ACE9",
  expertiseBlue: "#007FBE",

  textBlack100: "#000000",
  logoTypo700: "#6D6E71",
  backgrounds30: "#BEBDBC",
  backgrounds15: "#DEDEDD",

  coldLead: "#00AEEF",
  warmLead: "#F17B0E",
  hotLead: "#EE1B12",
};
