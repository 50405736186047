<template>
  <div
    style="width: 100%; height: 100%"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular indeterminate :color="color" />
  </div>
</template>

<script>
export default {
  name: "ZnapLoading",

  props: {
    color: { type: String, required: false, default: "serviceGreen" },
  },
};
</script>
