const initialState = () => {
  return {
    miniVariant: false,
  };
};

const state = initialState();

const getters = {
  getMiniVariant: (state) => state.miniVariant,
};

const mutations = {
  setMiniVariant(state, miniVariant) {
    state.miniVariant = miniVariant;
  },
};

export default {
  state,
  getters,
  mutations,
};
