const initialState = () => {
  return {
    selectedResale: null,

    resalesList: [],
  };
};

const state = initialState();

const getters = {
  getSelectedResale: (state) => state.selectedResale,
  getResalesList: (state) => state.resalesList,
};

const mutations = {
  setSelectedResale(state, selectedResale) {
    state.selectedResale = selectedResale;
  },

  setResalesList(state, resalesList) {
    state.resalesList = resalesList;
  },
};

export default {
  state,
  getters,
  mutations,
};
