<template>
  <nav class="container neutralDark d-flex justify-center">
    <v-row no-gutters>
      <v-col
        v-for="menu in visibleMenus"
        :key="menu.id"
        class="d-flex justify-center align-center"
        @click="$emit('handleNavigate', menu)"
      >
        <v-icon :color="setMenuColor(menu)">
          {{ menu.icon }}
        </v-icon>
      </v-col>

      <v-col
        class="d-flex justify-center align-center"
        @click="$emit('handleLogout')"
      >
        <v-icon color="white"> mdi-logout </v-icon>
      </v-col>
    </v-row>
  </nav>
</template>

<script>
export default {
  name: "ZnapMobileNavigationDrawer",

  props: {
    menus: { type: Array, required: true },
    selectedMenu: { type: Object, required: true },
  },

  computed: {
    visibleMenus() {
      return this.menus.filter((m) => !m.hide);
    },
  },

  methods: {
    setMenuColor(menu) {
      if (
        this.selectedMenu !== null &&
        this.selectedMenu.id === menu.id &&
        this.$route.path === this.selectedMenu.route
      ) {
        return "warning";
      }

      return "white";
    },
  },
};
</script>

<style scoped>
.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 6;
}
</style>